<template>
  <div class="sidebar">
    <img src="../../assets/lil-logo.svg" alt="" width="51" height="16" @click.prevent="$router.push('/')">

      <div class="menu relative">
    <span class="items pointer" :id="index"  v-for="(item,index) in list" :key="index" @click.prevent="$router.push({name:item.path})" :class="item.class" @mouseenter="item.hover=true" @mouseleave="item.hover =false">

     <img class="pointer"  src="../../assets/panel/pointer.svg" alt="" v-if="$route.name.includes(item.path)" width="5" >
      <inline-svg :src="require(`../../assets/panel/${item.icon}.svg`)"
                  :class="{'active':$route.name.includes(item.path)}"
                  :style="{marginRight:!$route.name.includes(item.path) ? '15px' : '0px'}"
                  class="deactive"
      />

      <div class="secondary tool" v-if="item.hover" :id="`tooltip${index}`"  :class="{'active-tooltip' : item.hover =true}">

         <span >{{item.title}}</span>
<svg class="polygon" height="18" width="18">
  <polygon points="0,0 18,9 0,18" style="fill:#F5F4F2;stroke-width: 1" />
  Sorry, your browser does not support inline SVG.
</svg>
      </div>
    </span>
        <span class="logout" @click="$logout()" >
      <img src="../../assets/panel/logout-icon.svg" alt="">
    </span>


      </div>


  </div>
</template>
<script>
export default {
  name: "Sidebar",
  data() {
    return {
      title:false,
      list: [
        {
          icon: 'trade-icon',
          path: 'AdvanceTrade',
          name: 'AdvanceTrade',
          class:'',
          title:'معاملات حرفه ای',
          hover:false,
        },
        {
          icon:'otc-icon',
          path: 'OTC',
          name: 'OTC',
          class:'',
          title:'معاملات سریع',
          hover:false,
        },
        {
          icon: 'wallet-icon',
          path: 'Wallet',
          name: 'Wallet',
          class:'',
          title:'کیف پول',
          hover:false,
        },
        {
          path: 'Dashboard',
          icon: 'dashboard-icon',
          name: 'Dashboard',
          class:'',
          title:'داشبورد',
          hover:false,
        },
        {
          path: 'History',
          icon: 'history-icon',
          name: 'History',
          class:'',
          title:'تاریخچه',
          hover:false,
        },
        {
          icon: 'support-icon',
          path: 'Support',
          name: 'Support',
          class:'',
          title:'پشتیبانی',
          hover:false,
        },
        {
          path: 'Setting',
          icon: 'settings-icon',
          name: 'Setting',
          class:'',
          title:'تنظیمات',
          hover:false,
        },

        {
          path: 'Invitefriends',
          icon: 'invite-icon',
          name: 'Invitefriends',
          class:'',
          title:'دعوت دوستان',
          hover:false,
        },
{
  path:'Notifications',
  icon:'notification-icon',
  name:'Notifications',
  class:'mobile',
  title:'اعلانات',
  hover:false,
}

      ]
    };
  },


};
</script>
<style lang="scss" scoped>
.tool{
  position: absolute;

  .polygon{
    position: absolute;
    right: -17px;
    // z-index: -1;


  }
}
.active-tooltip{
  display: flex;
  position: absolute;
  top: -15px;
  right: 70px;
  font-size: 16px;
  width: 140px;
  background: #F5F4F2;
  border-radius: 8px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}
.tooltip{
display: none;
}
.mobile{
  display: none !important;
  opacity: 0;
}
.logout{
  margin:0 auto;
}
.sidebar {
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  //align-items: center;
  gap: clamp(20px, 3vw, 60px);

  background: var(--white);
}

.menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  //align-items: center;
  gap: 3.2em;
  //gap: clamp(30px, 2vw, 60px);
}

.items{
  display: flex;
  position: relative;
  gap: 10px;

  .pointer{


    align-self: center;
  }
}

.deactive{
  stroke:#8C8585;
}
.active {


  stroke: var(--secondary) !important;


}


@media(max-width:500px){
  .menu{
    gap: 20px;
  }
  .mobile{
opacity: 1;
    display: flex !important;
  }
}
</style>