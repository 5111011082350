<template>
<div class="panel">
<div class="side-bar">
  <sidebar />
</div>
<div class="main-content">
  <div class="panel-header w100" v-if="$route.name !=='AdvanceTrade'">
    <span class="secondary text-lg bolder">{{$panelNames[$route.name]}}</span>
<div class="notification-container"        :class="{'end': $route.name !== 'Dashboard'}"  >
  <div class="buttons" v-if="$route.name ==='Dashboard'" >
<button @click.prevent="state.dashboardType ='TOMAN'" :class="{'active-btn': state.dashboardType ==='TOMAN'}">تومان</button>
<button @click.prevent="state.dashboardType ='TETHER'" :class="{'active-btn': state.dashboardType ==='TETHER'}">تتر</button>
</div>

  <!-- <img class="mobile" @click.prevent="$router.push({name:'Notifications'})" src="../../assets/panel/panel-notification.svg" alt=""  :class="{'justify-content-end': $route.name !== 'Dashboard'}" > -->
  <!-- <inline-svg  :src="require('../../assets/panel/panel-notification.svg')"  @click.prevent="$router.push({name:'Notifications'})" :class="{'justify-content-end': $route.name !== 'Dashboard'}" /> -->
  <div class="notification mobile">
<img class="notif" src="../../assets/panel/notification-icon.svg" alt="" @click.prevent="$router.push({
name:'Notifications'
})">
<span class="dot"></span>
  </div>
    </div>
  </div>
  <router-view></router-view>
</div>
</div>



</template>

<script>
import sidebar from '../PublicComponents/Sidebar'
export default {
  name: "Panel",
  components:{
    sidebar
  }
}
</script>

<style lang="scss" scoped>
.end{
  justify-content: flex-end !important;
}

.notification{

  width: 48px;
  height: 48px;
  background: #F8F8F8;
  justify-content: center;
  position: relative;
  display: flex;
  align-items: center;
/* p2p cards */
.dot{
      transition: 500ms ease-in-out;
  width: 6px;
  height: 6px;
  background:#DA2C38;
  border-radius: 50%;
  position: absolute;
top: 12px;
left: 16px;
}
.notif{
        transition: 500ms ease-in-out;
}

box-shadow: 0px 0px 2px rgba(140, 150, 153, 0.34);
border-radius: 8px;


&:hover{
.notif{

  transform: rotate(-35deg);
}
.dot{
  transform: translate(-4px, 5px);
}
}

}

.panel{
  display: flex;
  //gap: 12px;
  padding: clamp(15px, 2vw, 60px) 0;
  position: relative;


}
.main-content{
  padding: 0 12px;
margin-right: 50px;
  min-height: 95vh;
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: clamp(32px, 1.2vw, 48px);
}

</style>
<style lang="scss" scoped>
.side-bar{
  position: fixed !important;
  right: 0;
  height: 100%;
}
.panel-header{
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  justify-content: space-between;
  align-items: center;
.notification-container{
  flex-wrap: wrap;
//justify-content: flex-end;
  display: flex;
  flex-direction: row;
  gap: 18px;
  align-items: center;
  width: 100%;
  max-width: 491px;

  .buttons{
    justify-content: center;
    padding: 6px 10px;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 420px;
    align-items: center;
    box-shadow: 0px 0px 2px rgba(140, 150, 153, 0.34);
    border-radius: 8px;
    background: var(--darker-white);
      }
  button{
    flex: 1;
    width: 100%;
    //max-width: 137px;
    height: 36px;
  }


}

}

@media(max-width:500px){
  .mobile{
    display: none;
  }
.notification-container{
  justify-content: flex-start;
  align-items: flex-start !important;
flex-direction: column !important;
  max-width: 250px;
}
  .buttons{
    max-width: 200px !important;
  }

.main-content{
  padding: 8px !important;
}
}

@media(max-width: 950px){
  .main-content{
    padding: 10px !important;
    max-width: 83% !important;
  }
}
</style>
<style lang="scss">

#bell , #bell-bottom{
transform: rotate(30deg);
cursor: pointer;
}

</style>